import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Layout from "../../components/Layout";
import { ArrowButton } from "../../components/Buttons";

function Home() {
    useEffect(() => {
        document.title = "Home - TrustSign";
    });

    console.log("Home", process.env);

    return (
        <Layout type="home">
            <div
                className="section-height-100 center-items parallax bg-home"
                id="home"
            >
                <Container>
                    <h1 className="color-white fst-italic title">
                        A inteligência e o conhecimento em segurança{" "}
                        <span className="color-gray">
                            sob-medida para seu negócio
                        </span>
                    </h1>
                    <p className="mt-3 mt-md-5 color-white title-description">
                        Dados de sua empresa são valiosos e estão sob constante
                        ameaça. A segurança corporativa exige medidas de
                        proteção e gerenciamento com foco total nas necessidades
                        de sua empresa.
                    </p>
                </Container>
            </div>
            <div
                className="section-height-100 parallax bg-home-2"
                id="quem-somos"
            >
                <Container className="d-flex align-items-center">
                    <Row>
                        <Col xs={12} md={9}>
                            <h3 className="color-white fst-italic mb-2 mb-md-4">
                                A TrustSign é uma empresa de segurança que atua
                                na prevenção, correção e gestão de riscos em
                                ambientes corporativos.
                            </h3>
                            <p className="color-white description mb-3 mb-md-4">
                                Somos empenhados no combate a vulnerabilidades
                                em aplicações web, servidores e sistemas
                                internos de empresas.
                            </p>
                            <ArrowButton className="btn-white-border" onClick={() => window.location.href = "/about"} icon="right">
                                Veja mais
                            </ArrowButton>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="section-height-100 center-items parallax bg-home-3"
                id="solucoes"
            >
                <Container>
                    <Row className="p-4 p-md-0">
                        <Col xs={12} md={6} className="p-2 col-custom">
                            <Card className="card-solucoes">
                                <Card.Header className="card-solucoes-header header-1" />
                                <Card.Body>
                                    <h5 className="color-blue fst-italic">
                                        PCI Compliance
                                    </h5>
                                    <p className="color-gray mt-4">
                                        Auditoria PCI DSS Compliance para
                                        empresas que coletam, armazenam e
                                        transmitem informações de cartões de
                                        crédito.
                                    </p>
                                </Card.Body>
                                <Card.Footer className="border-0 mb-2">
                                    <ArrowButton className="btn-blue-border" icon="right" onClick={() => window.location.href = "/products/pci"}>
                                        Veja mais
                                    </ArrowButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg className="p-2 col-custom">
                            <Card className="card-solucoes">
                                <Card.Header className="card-solucoes-header header-2" />
                                <Card.Body>
                                    <h5 className="color-blue fst-italic">
                                        Web Application Firewall - WAF
                                    </h5>
                                    <p className="color-gray mt-4">
                                        Serviços de WAF (Web Application
                                        Firewall) para manter suas aplicações
                                        livres de ataques comuns, bloqueando
                                        acessos indesejados.
                                    </p>
                                </Card.Body>
                                <Card.Footer className="border-0 mb-2">
                                    <ArrowButton className="btn-blue-border" icon="right" onClick={() => window.location.href = "/products/waf"}>
                                        Veja mais
                                    </ArrowButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg className="p-2 col-custom">
                            <Card className="card-solucoes">
                                <Card.Header className="card-solucoes-header header-3" />
                                <Card.Body>
                                    <h5 className="color-blue fst-italic">
                                        Certificados Digitais SSL
                                    </h5>
                                    <p className="color-gray mt-4">
                                        Certificados para aplicações web
                                        (simples e multidomínio), subdomínios,
                                        e-mails, códigos fonte e aplicações do
                                        Microsoft Exchange.
                                    </p>
                                </Card.Body>
                                <Card.Footer className="border-0 mb-2">
                                    <ArrowButton className="btn-blue-border" icon="right" onClick={() => window.location.href = "/products/ssl"}>
                                        Veja mais
                                    </ArrowButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg className="p-2 col-custom">
                            <Card className="card-solucoes">
                                <Card.Header className="card-solucoes-header header-4" />
                                <Card.Body>
                                    <h5 className="color-blue fst-italic">
                                        Teste de Invasão
                                    </h5>
                                    <p className="color-gray mt-4">
                                        Teste de Invasão para encontrar
                                        vulnerabilidades de segurança que as
                                        ferramentas automatizadas não
                                        identificam.
                                    </p>
                                </Card.Body>
                                <Card.Footer className="border-0 mb-2">
                                    <ArrowButton className="btn-blue-border" icon="right" onClick={() => window.location.href = "/products/pentest"}>
                                        Veja mais
                                    </ArrowButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg className="p-2 col-custom">
                            <Card className="card-solucoes">
                                <Card.Header className="card-solucoes-header header-5" />
                                <Card.Body>
                                    <h5 className="color-blue fst-italic">
                                        Gestão de Incidentes
                                    </h5>
                                    <p className="color-gray mt-4">
                                        Monitoramento e gestão de incidentes
                                        capazes de prejudicar dados
                                        confidenciais, bem como a reputação de
                                        seu negócio.
                                    </p>
                                </Card.Body>
                                <Card.Footer className="border-0 mb-2">
                                    <ArrowButton className="btn-blue-border" icon="right" onClick={() => window.location.href = "/products/incidents"}>
                                        Veja mais
                                    </ArrowButton>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section-height-100 parallax bg-home-4" id="contato">
                <Container className="d-flex align-items-center justify-content-center">
                    <Row>
                        <Col xs={12}>
                            <h4 className="color-white mb-4">
                                Fale <br /> Conosco
                            </h4>
                            <p className="color-white mb-5">
                                Preencha os dados para receber o contato de um
                                de nossos consultores.
                            </p>
                            <ArrowButton className="btn-white-border" icon="right" onClick={() => window.location.href = "/contact"}>
                                Entre em Contato
                            </ArrowButton>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}

export default Home;
